<template>
  <div class="resetPassword xi">
    <nh-com></nh-com>
    <div class="" style="margin-top: 30rpx">
      <div class="banner-wrap">
        <div class=" " style="color: #f0f2f2; font-size: 16px"><router-link to="/index">首页</router-link><i class="el-icon-arrow-right"></i><a>辨证论治</a><i class="el-icon-arrow-right"></i><a style="color: #666666">辨证论治系统</a></div>
      </div>
    </div>
    <div class="startBox">
      <div class="startBoxleft">
        <div class="drugTitle">
          <div class="drugTitle1">选择疾病</div>
          
          <div style="width: 30%">
            <el-input placeholder="请输入内容" v-model="searchValue" class="input-with-select" @keyup.enter.native="goSearchValue" clearable >
              <el-button @click="goSearchValue" slot="append" icon="el-icon-search"></el-button>
            </el-input>
          </div>
        </div>
        <div class="" style="margin-top: 10px">
          <!-- 主症列表 -->
          <div class="" style="margin-top: 15px">
            <div class="flex-l-b">
              <div class="" style="font-size: 16px; font-weight: bold">疾病分类</div>
                <div class="banner-right">
                <span @click="toPage">返回上一步</span>
              </div>
            </div>

            <div class="" style="display: flex; flex-wrap: wrap">
              <div class="" v-for="(item, index) in DiagnosiscategoryList1" :key="index" style="margin-top: 10px">
                <div :class="Diagnosiscategoryindex == index ? 'goodTagCheck' : 'goodTag'" @click="gotextDiagnosis(item, index)">
                  {{ item.primarySymptomName }}<span v-if="item.diseaseDepartmentName" style="color:#00AFB5"> {{ '(' + item.diseaseDepartmentName  + ')' }}</span>
                </div>
              </div>
              <div style="margin-top: 10px">
                <div v-if="pageIndex1 < pageCount1" class="goodTag" @click="gotextDiagnosiMore1">更多...</div>
              </div>
            </div>
          </div>
          <el-dialog modal title="提示" :visible.sync="dialogVisibleDetail" width="80%">
            <div class="dialogTitle">
              {{ selfDiagnosisPrimarySymptomDetail.primarySymptomName }}
            </div>
            <div class="textdetail" v-if="selfDiagnosisPrimarySymptomDetail.symptomDescription">
              <div class="">
                <p v-html="selfDiagnosisPrimarySymptomDetail.description.replace(/\n/g, '</p> <p>')"></p>
              </div>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisibleDetail = false">取 消</el-button>
              <el-button type="warning" @click="gostartSure">确 定</el-button>
            </span>
          </el-dialog>
        </div>
      </div>
    </div>
    <ft-com></ft-com>
  </div>
</template>

<script>
import ftCom from "../../components/footer";
import nhCom from "../../components/navheader";
import { zdrugconsultationMain } from "../../components/zdrugconsultation/zdrugconsultation";
export default {
  name: "resetPassword",
  components: {
    ftCom,
    nhCom,
  },
  data() {
    var zdrugconsultation = new zdrugconsultationMain(this.TokenClient, this.Services.Disease);
    return {
      zdrugconsultationMain: zdrugconsultation,
      nam: "123",
      startShow: true,
      sexShow: false,
      sexName: "",
      sexShowName: "男",
      sexindex: 0,
      ageShow: false,
      ageName: "",
      ageShowName: "",
      ageindex: 1,
      sexList: [{ name: "男" }, { name: "女" }],
      showPrimarySymptomName: "",
      primarySymptomNameText: "",
      opcategoryindex: -1,
      centerDialogVisible: false,
      categoryList: [],
      categoryindex: -1,
      categoryList1: [],
      categoryindex1: -1,
      categoryList2: [],
      categoryindex2: -1,
      DiagnosiscategoryList: [],
      Diagnosiscategoryindex: -1,
      selfDiagnosisPrimarySymptomDetail: {},
      selfId: "",
      questindex: 0,
      questionList: [],
      processList: [],
      recordId: "",
      resultList: {},
      UserSelfDiagnosisRecordList: [],
      http: "",
      imgtextindex: -1,
      optionname: "",
      pageIndex: 1,
      pageCount: 1,
      selfDiagnosisId: "",
      searchDialogVisible: false,
      pageIndex1: 1,
      pageCount1: 1,
      DiagnosiscategoryList1: [],
      dialogVisibleDetail: false,
      searchValue: "",
      selfSex: "",
      selfAge: "",
      quename: "",
      selectAnswerUser: this.$route.query.selectAnswerUser
    };
  },
  mounted() {
    this.gosearchCategory();
    this.http = this.Services.Statics;
  },
  methods: {
    toPage(){
      this.$router.back()
    },
    gosearchCategory() {
      this.DiagnosiscategoryList1 = [];
      this.getSelfDiagnosisPrimarySymptomList1();
    },
    // 主症列表
    getSelfDiagnosisPrimarySymptomList1() {
      var _this = this;
      _this.pageIndex1 = 1;
      _this.zdrugconsultationMain.getSelfTCMDiseasePrimarySymptom(
        0,
        "%20",
        JSON.parse( localStorage.getItem('selectAnswerUser1')).answerSex,
        JSON.parse( localStorage.getItem('selectAnswerUser1')).answerAge,
        _this.pageIndex1,
        function (data) {
          _this.DiagnosiscategoryList1 = data.data.results;
          _this.Diagnosiscategoryindex = -1;
          _this.pageCount1 = data.data.pageCount;
        },
        function (error) {
          if (error.statusCode == 404) {
            _this.DiagnosiscategoryList = [];
          }
        }
      );
    },
    goSearchValue() {
      var _this = this;
      let keyWord = "";
      if (_this.searchValue == "" || _this.searchValue == " ") {
        keyWord = "%20";
      } else {
        keyWord = _this.searchValue;
      }
      _this.pageIndex1 = 1;
      _this.zdrugconsultationMain.getSelfTCMDiseasePrimarySymptom(
        0,
        keyWord,
        JSON.parse( localStorage.getItem('selectAnswerUser1')).answerSex,
        JSON.parse( localStorage.getItem('selectAnswerUser1')).answerAge,
        _this.pageIndex1,
        function (data) {
          _this.DiagnosiscategoryList1 = data.data.results;
          _this.pageCount1 = data.data.pageCount;
          _this.Diagnosiscategoryindex = -1;
        },
        function (error) {
          if (error.statusCode == 404) {
            _this.DiagnosiscategoryList = [];
          }
        }
      );
    },
    gotextDiagnosiMore1() {
      var _this = this;
      let keyWord = "";
      if (_this.searchValue == "" || _this.searchValue == " ") {
        keyWord = "%20";
      } else {
        keyWord = _this.searchValue;
      }
      _this.pageIndex1 = _this.pageIndex1 + 1;
      _this.zdrugconsultationMain.getSelfTCMDiseasePrimarySymptom(
        0,
        keyWord,
        _this.pageIndex1,
        function (data) {
          _this.DiagnosiscategoryList1 = _this.DiagnosiscategoryList1.concat(data.data.results);
          _this.pageCount1 = data.data.pageCount;
        },
        function (error) {
          console.log(error);
          if (error.statusCode == 404) {
            _this.DiagnosiscategoryList1 = [];
          }
        }
      );
    },
    // 主症详情
    getSelfDiagnosisPrimarySymptomDetailList(e) {
      var _this = this;
      _this.zdrugconsultationMain.getSelfDiagnosisPrimarySymptomDetail(
        e,
        function (data) {
          _this.selfDiagnosisPrimarySymptomDetail = data.data;
          _this.selfSex = data.data.filterSex;
          _this.selfAge = data.data.minAge;
          _this.quename = data.data.primarySymptomName;
          _this.dialogVisibleDetail = true;
        },
        function (error) {
          console.log(error);
        }
      );
    },
    gostartSure() {
      this.dialogVisibleDetail = false;
      this.$router.push({
        path: "/diseaseConsultation/diseaseProgress",
        query: {
          id: this.selfId,
          selectAnswerUser: JSON.parse( localStorage.getItem('selectAnswerUser1')),
          age: this.selfAge,
          sex: this.selfSex,
          name: this.quename,
        },
      });
      
    },
    gotextDiagnosis(item, index) {
      this.Diagnosiscategoryindex = index;
      this.showPrimarySymptomName = item.primarySymptomName;
      this.getSelfDiagnosisPrimarySymptomDetailList(item.id);
      this.selfId = item.id;
    },
  },
};
</script>

<style scoped>
@import "../../assets/css/footer.css";
::v-deep .el-dialog {
  width: 48% !important;
  margin-top: 20vh !important;
}
.banner-wrap {
  position: relative;
  width: 1200px;
  height: 100%;
  margin: 0 auto;
}
.leftbox {
  display: flex;
  align-items: center;
}
.leftMessage {
  max-width: 240px;
  margin-top: 15px;
  font-size: 14px;
  padding: 15px;
  background-color: #f4f4f4;
  border-radius: 0px 15px 15px 15px;
  margin-left: 10px;
}
.resultMessage {
  width: 100%;
  margin-top: 15px;
  padding: 15px;
  margin-left: 10px;
  border: 1px solid #f2f2f2;
  box-shadow: 0px 5px 10px 0px rgba(153, 153, 153, 0.16);
  border-radius: 16px;
}
.rightbox {
  display: flex;
  justify-content: flex-end;
  text-align: right;
}
.rightMessage {
  max-width: 240px;
  margin-top: 15px;
  font-size: 14px;
  padding: 15px;
  background-color: #ee9e51;
  border-radius: 15px 0px 15px 15px;
  color: #fff;
}
.startBox {
  width: 1200px;
  margin: 30px auto;
  padding: 40px;
  box-shadow: 0px 6px 10px 0px rgba(204, 204, 204, 0.18);
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-image: url("../../assets/img/zdrugConsult/zdrugStartChart.jpg");
}
.startBoxleft {
  position: relative;
  width: 100%;
  min-height: 720px;
  background: #ffffff;
  border-radius: 20px;
  padding: 30px;
}
.startBoxleftTop {
  height: 500px;
  overflow: hidden;
  overflow-y: auto;
}
/*滚动条样式*/
.startBoxleftTop::-webkit-scrollbar {
  width: 4px;
}
.startBoxleftTop::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}
.startBoxleftTop::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}
.startBoxleftBot {
  /* overflow-x:scroll; */
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-bottom: 15px;
  border-top: 1px solid #e6e6e6;
  position: relative;
}
.startBoxleftBotn {
  margin-top: 12px;
  text-align: right;
  margin-right: 30px;
}
.startBoxright {
  margin-left: 5%;
  width: 30%;
  height: 720px;
  padding: 30px;
  background: #ffffff;
  border-radius: 20px;
  position: relative;
}
.surebtn {
  background: linear-gradient(180deg, #ffb163, #ee9e51);
  font-size: 16px;
  color: #ffffff;
  padding: 17rpx 0;
  width: 100%;
  text-align: center;
}
.goodTagCheck {
  font-size: 18px;
  text-align: center;
  padding: 5px 32px;
  background-color: #ffe6cd;
  border-radius: 20px;
  margin-left: 10px;
  color: #df7d1f;
  margin-top: 10px;
  cursor: pointer;
}
.goodTag {
  font-size: 18px;
  text-align: center;
  padding: 5px 32px;
  background-color: #f2f2f2;
  border-radius: 20px;
  margin-left: 10px;
  margin-top: 10px;
  cursor: pointer;
}
/* 症状 */
.textdetail {
  padding: 15px;
  background-color: #f2f2f2;
  border-radius: 12px;
  margin-top: 10px;
}
.viptable {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  overflow-x: auto;
}
.viptableY {
  width: 100%;
  height: 150px;
  overflow: hidden;
  overflow-y: auto;
}
.endbox {
  margin-top: -50px;
  padding: 15px;
  background: #ffffff;
  box-shadow: 0px 6px 0px 0px #f3d6bc;
  border-radius: 10px;
  border: 1px solid #f3d6bc;
}
.endboxright {
  margin-top: 15px;
  padding: 10px 15px;
  background: #ffffff;
  box-shadow: 0px 6px 0px 0px #f3d6bc;
  border-radius: 10px;
  border: 1px solid #f3d6bc;
  cursor: pointer;
}
.flexlr {
  display: flex;
  justify-content: space-between;
}
.radioBox {
  margin: 10px 0;
  font-size: 18px;
}
.imgTag {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  margin-left: 10px;
  border-radius: 8px;
}
.imgTagCheck {
  background-color: #feaf62;
  /* opacity: 0.5; */
  padding: 5px;
}
.textCheck {
  color: #df7d1f;
}
.textBox {
  display: flex;
  flex-wrap: wrap;
}
.symptomsText {
  font-size: 15px;
  color: #666666;
  margin: 8px 0;
  padding-left: 22px;
  /* overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical; */
}
.itembox {
  width: 50%;
}
.nodataimg {
  width: 100%;
  text-align: center;
  margin-top: 30px;
}
.hide-tooltip {
  max-width: 400px;
  /* visibility: hidden; */
}
.newChecked {
  color: #df7d1f;
}
.drugTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  padding-right: 40px;
  align-items: center;
}
.drugTitle1 {
  font-size: 28px;
  color: #333333;
}
.zdrugInputclass {
  width: 100%;
  padding: 10px 20px;
  background: #fafafa;
  border: 1px solid #e7a365;
  border-radius: 20px;
  font-size: 20px;
  color: #666666;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.zdrugInputIcon {
  padding: 7px 17px;
  border-radius: 17px;
  background-color: #e7a365;
  color: #fff;
}
.dialogTitle {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}
.flex-l-b {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.banner-right {

}
.banner-right span {
    display: inline-block;
    margin-left: 20px;
    width: 132px;
    height: 32px;
    border: 1px solid #333333;
    border-radius: 32px;
    box-sizing: border-box;
    text-align: center;
    color: #333333;
    line-height: 30px;
    cursor: pointer;
}

.banner-right span:hover {
    background-color: #ffffff;
    color: #00ab98;
}
</style>
<style>
.personal-center {
  display: none;
}
</style>
